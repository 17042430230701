import { TranslateService } from '@ngx-translate/core';
import { ITab } from './';

export class TabManager {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _tabs: Array<ITab> = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _selected: string;

    constructor(private readonly translateService: TranslateService) {}

    public select(tab: string) {
        if (this._selected === tab) {
            return;
        }
        const iTab = this.tabs.find((t) => t.name === tab);
        if (iTab) {
            this._selected = iTab.name || this.getFirst();
        } else {
            this._selected = this.getFirst();
        }
    }
    public get selected() {
        return this._selected || this.getFirst();
    }
    public isSelected(tab: string) {
        return (this._selected || this.getFirst()) === tab;
    }
    public get tabs() {
        return this._tabs.filter((x) => !x.hidden);
    }
    public set tabs(tabs: Array<ITab>) {
        if (tabs) {
            tabs.filter((t) => !!t.text).forEach((tab) => {
                this.translateService.get(tab.text).subscribe((translation) => (tab.text = translation));
            });
        }
        this._tabs = tabs || [];
    }
    public getTab(tab: string) {
        return this.tabs.find((t) => t.name === tab);
    }
    public getFirst() {
        return this.tabs && this.tabs[0] && this.tabs[0].name;
    }
}
