import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { Subscription } from 'rxjs';
import { ITab } from '.';
import { TabManager } from './tab-manager';

@Injectable()
export class RoutingTabManager extends TabManager implements OnDestroy {
    private _routerSubscription: Subscription;
    private _tabRoute: Array<string | number>;
    private _toSelectTab: string;
    private _toSelectParam: any;

    constructor(private route: ActivatedRoute, private readonly router: Router, translateService: TranslateService) {
        super(translateService);
        this._routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let firstChildPath = './';
                if (this.route.snapshot && this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0]) {
                    firstChildPath = this.route.snapshot.firstChild.url[0].path;
                }

                let firstChildParams;
                if (this.route.firstChild && this.route.firstChild.snapshot && this.route.firstChild.snapshot.firstChild) {
                    firstChildParams = this.route.firstChild.snapshot.firstChild.params['historyObjectId'];
                }
                // called everytime, because without it, [module/new => save => module/123 => navigate with tab] doesn't work
                this.regenerateBaseRoute();
                this.select(firstChildPath, firstChildParams);
            }
        });
        this.regenerateBaseRoute();
    }

    private regenerateBaseRoute() {
        const routes = [];
        const paths = this.route.snapshot.pathFromRoot.map((o) => o.url.map((x) => x.path)).filter((x) => Object.entries(x).length !== 0);
        paths.forEach((value) => {
            value.forEach((valueInner) => {
                routes.push(valueInner);
            });
        });
        if (Object.entries(routes).length !== 0) {
            this._tabRoute = routes;
        }
    }

    ngOnDestroy(): void {
        this._routerSubscription?.unsubscribe();
    }

    public get tabs() {
        return super.tabs;
    }

    public set tabs(tabs: Array<ITab>) {
        super.tabs = tabs;
        if (this._toSelectTab) {
            this.select(this._toSelectTab, this._toSelectParam);
        }
    }

    public select(tab: string, param?: string) {
        if (this._selected === tab) {
            return;
        }
        if (!this.tabs.length) {
            this._toSelectTab = tab;
            this._toSelectParam = param;
            return;
        }

        const iTab = this.tabs.find((t) => t.name === tab);
        if (iTab) {
            this._selected = iTab.name || this.getFirst();
        } else {
            this._selected = this.getFirst();
        }
        let navigateRoute = [...this._tabRoute];
        if (this.router && iTab && iTab.route) {
            navigateRoute = [...navigateRoute, ...iTab.route];
            if (param) {
                navigateRoute = [...navigateRoute, param];
            }
            this.router.navigate(navigateRoute, { queryParamsHandling: 'preserve' }).catch(EMPTY);
            return;
        }
        if (this.router && this._tabRoute) {
            navigateRoute = [...navigateRoute, this._selected];
            if (param) {
                navigateRoute = [...navigateRoute, param];
            }
            this.router.navigate(navigateRoute, { queryParamsHandling: 'preserve' }).catch(EMPTY);
            return;
        }
    }
}
